import { FC, memo } from 'react';
import { RiskRatingKeys } from '../../../models/Risk';
import Tooltip from '../../shared/Tooltip';
import { useTranslation } from 'react-i18next';
import RiskActionIndicator from '../../risk/RiskActionIndicator';
import { GQLRiskToPlatformRisk } from '../../../models/TableView';

const RiskRenderer: FC<{ data: string }> = (props) => {
  const { t } = useTranslation(['risk']);

  if (!props.data) return '-';

  const risk = GQLRiskToPlatformRisk[props.data];

  return (
    <Tooltip text={t(RiskRatingKeys[risk])}>
      {(tooltip) => (
        <span {...tooltip}>
          <RiskActionIndicator riskRating={risk} flagOnly />
        </span>
      )}
    </Tooltip>
  );
};

export default memo(RiskRenderer);
